<template>
  <modal :adaptive="true"
         :clickToClose="true"
         :draggable="false"
         :name="'car_sell_reject_modal_' + car.id"
         :resizable="false"
         :scrollable="false"
         :shiftX="0.5"
         :shiftY="0.5"
         :height="'auto'"
         overlayTransition="fade"
         transition="fade">
    <div class="box box-primary box-modal mb-0">
      <div class="box-header with-border mb-3">
        <h4>#{{ car.id }} {{ car.title }}</h4>
      </div>

      <div class="box-body">
        <form @keydown="form.onKeydown($event)" @submit.prevent="saveCarSellReject">
          <div class="form-group">
            <textarea class="form-control"
                      placeholder="Причина снятия с продажи"
                      rows="4"
                      v-model="form.comment"></textarea>
            <HasError :form="form" field="comment"/>
          </div>

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

          <Button :disabled="!validateData || form.busy"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-success pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-save"></i>
          </Button>

          <button class="btn btn-default btn-lg pull-right margin-r-10"
                  title="Отменить и закрыть окно"
                  type="button"
                  v-on:click="hideCarSellRejectModal(car.id)">
            <i class="far fa-window-close"></i>
          </button>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import VModal from 'vue-js-modal';
import {VTab, VueTabs} from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
import Form from 'vform';
import Multiselect from 'vue-multiselect';
import {VueTelInput} from 'vue-tel-input';
import phone_config from "@/assets/data/vue-tel-input-config.json";

Vue.use(VModal);

Form.axios = API.apiClient;

export default {
  name: 'car-sell-reject-modal',

  components: {
    VueTabs,
    VTab,
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
    VueTelInput,
  },

  props: {
    car: {
      type: Object,
    },
    hide_deposit: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    form: new Form({
      comment: null,
    }),
  }),

  computed: {
    validateData: function () {
      return this.form.comment;
    },
  },

  methods: {
    async hideCarSellRejectModal(carId) {
      this.$modal.hide('car_sell_reject_modal_' + carId);
    },
    async saveCarSellReject() {
      await this.form.post('/car/' + this.car.id + '/sell_reject/add').then(() => {
        showSuccess();
        window.location.reload();
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped>

</style>
